<template>
  <div class="text-left pb-4">
    <!-- <BaseHeader :title="'Editors'"></BaseHeader> -->

    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="loading"
    ></BaseLoading>

    <section class="" v-else>


      <div   class="card d-flex align-items-center flex-row w-100 justify-content-between  p-3  text-left my-1 " style="">
        <h2 class="card-title w-50 font-weight-black text-capitalize">
          Writers
        </h2>
        <!-- <div class=" w-50 d-flex border border-2 rounded align-items-center justify-content-start">
        <select style="border-right:1px solid #DDDDDD " v-model="searchOption" name="filterby" class="h-100 py-2 filter-by px-3" id="">
          <option  value="email">Search by EMAIL</option>
          <option  value="names">Search by NAME</option>
          <option  value="id">Search by ID</option>
          <option  value="phone">Search by PHONE</option>
          <option  value="country">Search by COUNTRY</option>
          <option  value="editor">Search by EDITOR</option>
          <option  value="level">Search by LEVEL</option>



        </select> -->
        <!-- {{searchOption}} -->

        <!-- <input type="text" class=" py-2 px-3 filter-field w-100" v-model="searchValue" placeholder="Search Writers "> -->

        <v-text-field
            v-model="searchValue"
            dense
            outlined
            label="Search Writers By Email "
            name="url"
            type="text"
            hide-details
          />
        <!-- </div> -->

      </div>

      <!-- {{writers}} -->
      <div class=" my-2  writer-table-custom ">
      
      <div class=" order-listing-header  row my-2  p-3 mx-sm-0 mx-1 ">  

        <div class="col-1 text-left d-flex align-items-center justify-content-start "><input type="checkbox" style="cursor:pointer!important;" class="mx-1" v-model="selectAll"> ID </div>
        <div class="col-2 text-left ">Name</div>
        <div class="col-2 text-left">Email</div>
        <div class="col-1 text-left"> Phone</div>
        <div class="col-1 text-left">Country</div>
        <div class="col-1 text-left">Editor</div>
        <div class="col-1 text-left">Level</div>
        <div class="col-1 text-left">Status</div>
        <div class="col-1 text-left"> Rating</div>
        <div class="col-1 text-left">Action</div>
        <!-- <div class="col-2 text-left">Action </div> -->
     </div>

     <!-- {{editors}} -->
   
     <div   v-if="filteredWriters.length >  0" class="">
     <div v-for="(editor, index) in filteredWriters" :key="index + 'ords'" class="order-listing-body row px-3 py-1 mx-sm-0 mx-1 ">  
        <div class="col-1 d-flex align-items-center justify-content-start ">
          <!-- <input type="checkbox" style="cursor:pointer!important;" :id="editor.id" :value="editor.id" v-model="selectedWriters" > -->
          <v-checkbox
            v-model="selectedWriters"
            :id="editor.id" :value="editor.id"
            
          ></v-checkbox>

          <router-link class="text-left px-2"
            :to="{ name: 'WriterProfile', params: { id: editor.id } }"
            >{{ editor.id || "" }}</router-link>
           
        </div>
        <div class="col-2 d-flex align-items-center justify-content-start text-left">
          <router-link
            :to="{ name: 'WriterProfile', params: { id: editor.id } }"
            >{{ editor.names || "" }}</router-link
          >
          <!-- <div class="text-slate-500 text-xs whitespace-nowrap mt-0.5">{{ order.subject_area_text || "" }}</div> -->
        </div>
        <div class="col-2 d-flex align-items-center justify-content-start text-left">{{ editor.email || "" }}</div>
        <div class="col-1 d-flex align-items-center justify-content-start text-left"
        
        >  {{ editor.phone || "" }} </div>
        <div class="col-1 d-flex align-items-center justify-content-start text-left"
        
        >  {{ editor.country || "" }}  </div>
        <div class="col-1 d-flex align-items-center justify-content-start text-left"
        
        >  {{ editor.editor || "" }} </div>
        <div class="col-1 d-flex align-items-center justify-content-start text-left"
        
        >  {{ editor.level || "" }} </div>
        <div class="col-1 d-flex align-items-center justify-content-start text-left">
          <template >
            <v-btn
              v-if="editor.status == '1'"
              outlined
              color="primary"
              text-color="primary"
              style="cursor: pointer"
              rounded
              small
              @click="
                activeWriter = editor;
                dialog = true;
                action = 'deactivate';
              "
              >Active
              <v-icon color="primary" text-color="primary" right
                >mdi-close</v-icon
              ></v-btn
            >
            <v-chip
              style="cursor: pointer"
              v-else

              color="error"
              text-color="error"
              medium
              rounded
              outlined
              @click="
                activeWriter = editor;
                dialog = true;
                action = 'activate';
              "
              >Inactive <v-icon right>mdi-pencil</v-icon></v-chip
            >
          </template>
        </div>
        <div class="col-1 d-flex align-items-center justify-content-start text-left">{{ editor.rating || "" }}</div>

        <div class="col-1 d-flex align-items-center justify-content-start text-left  ">
          <div class="btn-group" v-if="editor.id">        
            <v-btn
              @click="editWriterPreferences(editor)"
              rounded
              color="primary"
            >
              <v-icon left>
                mdi-pencil
              </v-icon>
              Edit
            </v-btn>
          
        </div>
        </div>
     </div>
     <div class="card-footer" v-if="selectedWriters.length > 0">
      <p>With selected:</p>
      <!-- <div class="btn-group">
        <button
          class="btn bg-teal"
          @click="bulkAction('MakeEditor')"
        >
          Make Editor
        </button>
        <button
          class="btn bg-primary"
          @click="bulkAction('MakeWriter')"
        >
          Make Writer
        </button>
      </div> -->
      <v-btn-toggle
        
      >

        <v-btn @click="bulkAction('MakeEditor')" value="right" color="primary"  >
          <span class="hidden-sm-and-down"> Make Editor</span>

        </v-btn>

        <v-btn @click="bulkAction('MakeWriter')" value="justify" color="success" outlined >
          <span class="hidden-sm-and-down">Make Writer</span>
        </v-btn>
      </v-btn-toggle>
    </div>
    </div>
    <div class="text-center w-100  py-2"  v-if="filteredWriters.length < 1">
        
          <strong class="custom_imageEmpty  text-center" >No editors.</strong>
        
    </div>

     <!-- activate deactivate writer -->
     <v-dialog
        v-model="dialog"
        max-width="290"
        class="text-left"
        transition="dialog-top-transition"
      >
        <v-card>
          <v-card-title class="text-h5 text-capitalize">
            {{ action }} writer
          </v-card-title>
          <br>

          <v-card-text class="text-left">
            Are you sure you want to {{ action }}
            <span class="text-weight-bold">{{ activeWriter.names || "" }}</span>
          </v-card-text>

          <v-card-actions class="" >
            <v-col class="text-right">
            <v-btn 
            color="error"
            outlined 
            small
            @click="dialog = false">
              Cancel
            </v-btn>

            <v-btn
              color="primary "
              outlined 
              small
              class="ma-2"
              @click="changeStatus(action)"
              :disabled="writerPrefsForm.busy"
            >
              Yes, {{ action }}
            </v-btn>
          </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- activate deactivate writer -->

      <!-- writer levels modal -->
      <v-dialog
        v-model="$store.state.dialog"
        class="text-left"
        transition="dialog-top-transition"
        max-width="600"
      >
        <v-card>
          <v-card-title class="text-h5">
            Update Writer Preferences
          </v-card-title>
          <v-card-text>
            <!--  form -->
            <v-form class="flex-fill" @submit.prevent="updateWriterPreferences">
              <div class>
                <div class="text-left mb-3">
                  <p class="font-weight-bold">
                    Writer: {{ activeWriter.names || "" }}
                  </p>
                  <span class="d-block text-muted"
                    >All fields are required</span
                  >
                </div>

                <v-row>
                  <v-col cols="12" md="12">
                    <v-select
                      dense
                      outlined
                      label="Writer Level"
                      name="level_id"
                      :items="writerLevels"
                      item-text="level"
                      item-value="id"
                      v-model="writerPrefsForm.level_id"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="writerPrefsForm"
                            class="v-messages theme--light error--text"
                            field="level_id"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" md="12" v-if="userType == userTypes.admin">
                    <v-select
                      dense
                      outlined
                      label="Editor"
                      name="editor_id"
                      :items="editors"
                      item-text="names"
                      item-value="id"
                      v-model="writerPrefsForm.editor_id"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="writerPrefsForm"
                            class="v-messages theme--light error--text"
                            field="editor_id"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col class="text-right">
                    <!-- <v-btn 
                    color="error"
                    outlined 
                    
                    @click="dialog = false">
                      Cancel
                    </v-btn> -->
                    <v-btn
                      :disabled="writerPrefsForm.busy"
                      type="submit"
                      color="primary"
                      class="white--text ma-3 "
                      >Update</v-btn
                    >
                  </v-col>
                </v-row>
              </div>
            </v-form>

            <!-- / form -->
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- writer levels modal -->
    <!-- {{selectedWriters}} -->

    </div>

     
    </section>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  name: "Writers",
  computed: {
    // ...mapState("editors", ["loading", "editors", "headers"]),
    ...mapState("writers", [
      "loading",
      "writers",
      "headers",
      "writerPrefsForm",
      "bulkActionForm",
    ]),
    filteredWriters() {
        let tempRecipes = this.writers
        console.log("this is search option", this.searchOption)
        // Process search input
        if (this.searchValue != '' && this.searchValue) {
          // const flatten = (input) => {

          // const option = this.searchValue
            tempRecipes = tempRecipes.filter((item) => {
              // const computedObj = { ...item,
              //   item.id,
              // }
              return item.email
                  .toUpperCase()
                  .includes(this.searchValue.toUpperCase())
              })
          
          //   return tempRecipes.filter((item) => {
          //   const computedObj = { ...item,
          //       email:item.email
          //   }
          //   return Object.keys(computedObj)
          //         .some(key => ('' + computedObj[key]).toUpperCase().includes(this.searchOption.toUpperCase()))
          // })
           
        }

        // const flatten = (input) => {
        //   if (typeof input === 'object') {
        //     return (Array.isArray(input) ? input : Object.values(input))
        //       .reduce((acc, x) => acc.concat(flatten(x)), [])
        //   } else {
        //     return [input]
        //   } 
        // }
        // console.log(flatten(tempRecipes).includes(this.searchValue))

       
        
        // Filter out by cooking time
        // if (this.maxCookingTime)
        // tempRecipes = tempRecipes.filter((item) => {
        //     return (item.cookingTime <= this.maxCookingTime)
        // })
            
        // Sort by alphabetical order
            tempRecipes = tempRecipes.sort((a, b) => {
                if (this.sortBy == '0') {
                    let fa = a.status.toLowerCase(), fb = b.status.toLowerCase()
            
                if (fa < fb) {
                    return -1
                }
                if (fa > fb) {
                    return 1 
                }
                return 0
                
                // Sort by cooking time
                } else if (this.sortBy == '1') {
                return a.cookingTime - b.cookingTime
            }
            })
            
            // Show sorted array in descending or ascending order
            if (!this.ascending) {
                tempRecipes.reverse()
            }
            
            return tempRecipes
    },
    selectAll: {
            get: function () {
                return this.filteredWriters ? this.selectedWriters.length == this.filteredWriters.length : false;
            },
            set: function (value) {
                var selected = [];

                if (value) {
                    this.filteredWriters.forEach(function (writer) {
                        selected.push(writer.id);
                    });
                }

                this.selectedWriters = selected;
            }
    },
    ...mapState("auth", ["user"]),
    ...mapState("editors", ["editors"]),
    ...mapState("writerlevels", ["writerLevels"]),
    ...mapGetters("auth", ["userType", "userTypes"]),
  },
  data() {
    return {
      countries: [],
      activeWriter: {},
      dialog: false,
      searchValue:"",
      action: "",
      selectedWriters:[],
      ascending: true,
      searchOption:'email',
      sortBy:"All"
    };
  },

  methods: {
    ...mapActions("writers", ["getAllWriters", "updateWriterPrefs"]),
    ...mapActions("editors", ["getEditors"]),
    ...mapActions("writerlevels", ["getWriterLevels"]),

    editWriterPreferences(writer) {
      this.$store.state.dialog = true;
      this.activeWriter = writer;
      this.writerPrefsForm.writer_id = writer.id;
    },

    updateWriterPreferences() {
      if (this.userType == this.userTypes.editor) {
        this.writerPrefsForm.editor_id = this.user.id;
      }
      this.updateWriterPrefs(this.writerPrefsForm)
        .then(() => {
          this.$store.state.dialog = false;
          this.$notify({
            title: "Success",
            text: "Writer preferences updated successifully.",
            style: "success",
          });
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            text: "Error while updating writer preferences",
            style: "danger",
          });
        });
    },

    changeStatus(action) {
      console.log("action", action);
      (this.writerPrefsForm.writer_id = this.activeWriter.id),
        (this.writerPrefsForm.status = action == "activate" ? 1 : Boolean(0));

      this.writerPrefsForm
        .put(`${process.env.VUE_APP_API_BASE_URL}/Writers/Status`)
        .then(() => {
          this.$notify({
            title: "Success",
            text: "Writer status updated successifully.",
            style: "success",
          });
          this.dialog = false;
          this.getAllWriters();
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            text: "Error while updating writer status",
            style: "danger",
          });
        });
    },

    bulkAction(action) {
      // let writer_ids = this.selectedWriters.map(
      //   (item) => item.id || ""
      // );
      console.log("this is the selected writers", this.selectedWriters)

      this.bulkActionForm.writer_ids = [];
      this.bulkActionForm.writer_ids = this.selectedWriters;

      this.bulkActionForm
        .put(`${process.env.VUE_APP_API_BASE_URL}/Writers/${action}`)
        .then(() => {
          this.$notify({
            title: "Success",
            text: "Writer status updated successifully.",
            style: "success",
          });
          this.dialog = false;
          this.bulkActionForm.reset();
          this.getAllWriters();
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            text: "Error while updating writer status",
            style: "danger",
          });
        });
    },
  },

  async mounted() {
    this.$store.state.loading = true;
    await this.getAllWriters();
    await this.getWriterLevels();
    if (this.userType == this.userTypes.admin) {
      await this.getEditors();
    }
  },
};
</script>